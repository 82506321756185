import { ReactElement, useContext } from "react";
import IframeContext from "../../contexts/IframeContext";

interface PageContainerProps {
  children: ReactElement | ReactElement[];
  minHeight?: string;
}

export const PageContainer = ({ children, minHeight }: PageContainerProps) => {
  const isIframe = useContext(IframeContext);
  const widthClasses = isIframe ? "w-full" : "rounded-3xl drop-shadow-md";

  return (
    <div
      className={`
        h-[60vh] w-[90vw]
        ${widthClasses} 
        tallAndSlim:min-h-[40vh] tallAndSlim:h-auto
        wideAndShort:h-[65vh] wideAndShort:w-[95vw] wideAndShort:px-8
        medium:h-[80vh] medium:w-[80vw] medium:py-8 medium:px-8
        tallAndWide:py-4 tallAndWide:px-8
        self-center bg-white px-4 py-4 overflow-auto `}
    >
      {children}
    </div>
  );
};
