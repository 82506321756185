import React, { useContext, useEffect } from "react";
import { PageContainer } from "../../../shared/PageContainer";
import CameraSelector from "./CameraSelector";
import { Button } from "../../../shared/Button";
import { useUserCheck } from "../../../../utility/useUserCheck";
import IframeContext from "../../../../contexts/IframeContext";
import { setPath } from "../../../../utility/setPath";
import { useNavigate } from "react-router-dom";

type Props = {};

const VideoTestStages = {
  information: "information",
  camera: "camera",
} as const;

export const CameraStages = {
  leftEar1: 0,
  leftEar2: 1,
  leftEar3: 2,
  leftEarDone: 3,
  rightEar1: 4,
  rightEar2: 5,
  rightEar3: 6,
  done: 7,
} as const;

export const VideoTestNonIntegrated = (props: Props) => {
  const userIsSet = useUserCheck();
  const isIframe = useContext(IframeContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userIsSet) {
      navigate(setPath("/occupational-health", isIframe));
    }
  }, [userIsSet, isIframe, navigate]);

  const [videoTestStage, setVideoTestStage] = React.useState<
    keyof typeof VideoTestStages
  >(VideoTestStages.information);

  const [cameraStage, setCameraStage] = React.useState<
    (typeof CameraStages)[keyof typeof CameraStages]
  >(CameraStages.leftEar1);

  const showCameraStage = () => {
    switch (cameraStage) {
      case CameraStages.leftEar1:
        return (
          <div>
            <h2 className="text-2xl font-bold">Left Ear Picture 1</h2>
            <p className="">Please take a picture of your left ear canal.</p>
          </div>
        );
      case CameraStages.leftEar2:
        return (
          <div>
            <h2 className="text-2xl font-bold ">Left Ear Picture 2</h2>
            <p className="">Please take a picture of your left ear canal.</p>
          </div>
        );
      case CameraStages.leftEar3:
        return (
          <div>
            <h2 className="text-2xl font-bold ">Left Ear Picture 3</h2>
            <p className="">Please take a picture of your left ear canal.</p>
          </div>
        );
      case CameraStages.rightEar1:
        return (
          <div>
            <h2 className="text-2xl font-bold ">Right Ear Picture 1</h2>
            <p className="">Please take a picture of your right ear canal.</p>
          </div>
        );
      case CameraStages.rightEar2:
        return (
          <div>
            <h2 className="text-2xl font-bold ">Right Ear Picture 2</h2>
            <p className="">Please take a picture of your right ear canal.</p>
          </div>
        );
      case CameraStages.rightEar3:
        return (
          <div>
            <h2 className="text-2xl font-bold ">Right Ear Picture 3</h2>
            <p className="">Please take a picture of your right ear canal.</p>
          </div>
        );
      default:
        return <></>;
    }
  };

  if (videoTestStage === VideoTestStages.information) {
    return (
      <PageContainer>
        <h2 className="text-2xl font-bold ">Video Test Information</h2>
        <p className="">
          This test will require you to take some pictures of your ear canal.
          <br />
          You will need to take 3 pictures of each ear. <br /> When you press
          start, you will be asked to take in-ear pictures, please follow the
          instructions on screen.
        </p>
        <Button
          text="Start Test"
          onClick={() => setVideoTestStage(VideoTestStages.camera)}
          dataTest="start-video-test"
        />
      </PageContainer>
    );
  }

  if (cameraStage === CameraStages.leftEarDone) {
    return (
      <PageContainer>
        <h2 className="text-2xl font-bold mb-4">Left Ear Complete</h2>
        <p className="mb-4">
          You have completed the left ear pictures. <br />
          Please now take the pictures of your right ear.
        </p>
        <Button
          text="Continue"
          onClick={() => setCameraStage(CameraStages.rightEar1)}
          dataTest="continue-to-right-ear"
        />
      </PageContainer>
    );
  }

  if (cameraStage === CameraStages.done) {
    return (
      <PageContainer>
        <h2 className="text-2xl font-bold mb-4">Right Ear Complete</h2>
        <p className="mb-4">
          You have completed the right ear pictures, this concludes the video
          test. <br />
          Please now continue to the next test.
        </p>
        <Button
          text="Continue"
          onClick={() => {
            navigate("/occupational-health/redirect");
          }}
          dataTest="continue-to-next-test"
        />
      </PageContainer>
    );
  }
  return (
    <PageContainer>
      {showCameraStage()}
      <CameraSelector
        pictureNumber={cameraStage}
        setPictureNumber={setCameraStage}
      />
    </PageContainer>
  );
};
