import { useState } from "react";
import { TestStage } from "../../shared/models/test-stage";
import { Trial } from "../../shared/models/trial";
import { LoggingService } from "../../shared/services/logging-service";
import { Spinner } from "../../shared/Spinner";
import { HearingTrial } from "./HearingTrial";
import { TestStart } from "./test-start/TestStart";

export interface HearingTestPanelProps {
  trial?: Trial;
  postTrial: (patiantResponse: number) => void;
  loading: boolean;
  volumePercentage: number;
}

export const HearingTestPanel = (props: HearingTestPanelProps) => {
  const [acceptedStart, setAcceptedStart] = useState<boolean>(false);

  const panelContent = () => {
    if (!props.trial) {
      return <Spinner></Spinner>;
    }

    if (props.trial?.stage === TestStage.start && !acceptedStart) {
      return <TestStart startConfirmed={() => setAcceptedStart(true)} />;
    }

    if (props.trial?.stage === TestStage.start && acceptedStart) {
      let obj = {
        name: "Start Test",
        properties: {
          component: "HearingTestPanel",
          message: "Test started",
        },
      };

      LoggingService.InfoLogger(obj);
      return (
        <HearingTrial
          loading={props.loading}
          trial={props.trial}
          postTrial={props.postTrial}
          volumePercentage={props.volumePercentage!}
        />
      );
    }

    return <Spinner></Spinner>;
  };

  return <div className="flex flex-col h-full">{panelContent()}</div>;
};
