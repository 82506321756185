import { useContext, useEffect, useState, useRef, useCallback } from "react";
import IframeContext from "../../../contexts/IframeContext";
import { HearingTestService } from "../../hearing-test/services/hearing-test-service";
import { HseV1Categorisation } from "../../shared/models/client-categorisation";
import { PageContainer } from "../../shared/PageContainer";
import audiogramLineDataObject from "../functions/audiogramLineDataObject";
import { intensityKey } from "../functions/intensityKey";

import { newTrafficLightResult } from "../functions/newTrafficLightResult";
import { reportOverallResult } from "../functions/reportOverallResult";
import { AdminApi } from "../../hearing-test/services/admin-api";

import html2canvas from "html2canvas";
import { PartyCodeContext } from "../../../contexts/PartyCodeContext";
import { AudiogramChart } from "../functions/AudiogramChart";
import { useAtomValue, useSetAtom } from "jotai";
import {
  resetAllAtoms,
  userDetailsAtom,
  userInputsAtom,
  videoTestAtom,
} from "../../../atoms/atoms";
import { setPath } from "../../../utility/setPath";
import { Button } from "../../shared/Button";
import { Text } from "../../hearing-test/components/styled/Text";
import { useNavigate, useParams } from "react-router-dom";

export const audiogramLineData = audiogramLineDataObject();

export const audiogramConfig = {
  type: "line",
  data: audiogramLineData,
};

export const LatusResult = (props: {}) => {
  const isIframe = useContext(IframeContext);
  const userDetails = useAtomValue(userDetailsAtom);
  const [category, setCategory] = useState<HseV1Categorisation>();
  const [userData, setUserData] = useState<any>(null);
  const exportRef = useRef<HTMLDivElement>(null);
  const partyDetails = useContext(PartyCodeContext);
  const videoTestResult = useAtomValue(videoTestAtom);
  const userInputs = useAtomValue(userInputsAtom);
  const navigate = useNavigate();

  const { testId, externalId, userId, userDataParam } = useParams();

  const resetAtoms = useSetAtom(resetAllAtoms);

  //Collect user data to save
  useEffect(() => {
    if (userDataParam) {
      let cryptUserData = atob(userDataParam);
      let arrayUserData = cryptUserData.split("£");

      let fullName = arrayUserData[0];
      let userEmail = arrayUserData[1];
      let gender = arrayUserData[2];
      let age = arrayUserData[3];

      setUserData({
        fullName: fullName,
        email: userEmail,
        age: age,
        gender: gender,
      });
    }
  }, [userDataParam]);

  const saveLatusResults = useCallback(
    (categorisation: any) => async (categorisation: any) => {
      let element = exportRef.current;
      if (element) {
        setTimeout(() => {
          html2canvas(element!).then((canvas: any) => {
            if (exportRef.current) {
              let imageBase64 = exportRef.current
                .getElementsByTagName("canvas")[0]
                .toDataURL("image/png", 0.5);
              if (imageBase64) {
                AdminApi.saveLatusResults(
                  categorisation,
                  userData,
                  partyDetails.slug,
                  imageBase64,
                  userId,
                  videoTestResult,
                  userInputs
                ).then((resultWP) => console.log(resultWP));
              }
            }
          });
        }, 1500);
      }
    },
    [partyDetails.slug, userData, userId, userInputs, videoTestResult]
  );

  useEffect(() => {
    if (externalId && partyDetails.slug && testId && userId && userData) {
      if (userId !== userDetails.userId) {
        navigate(setPath("/occupational-health", isIframe));
      } else {
        HearingTestService.getResultCategorisation(externalId).then(
          (responseCategory) => {
            setCategory(responseCategory);
            saveLatusResults(responseCategory);
          }
        );
      }
    }
  }, [
    externalId,
    userData,
    isIframe,
    testId,
    userId,
    userDetails.userId,
    partyDetails.slug,
    navigate,
    saveLatusResults,
  ]);

  function startNewTest() {
    resetAtoms(true);
    navigate(setPath("/occupational-health", isIframe));
  }

  const returnToYodha = () => {
    sessionStorage.clear();
    const testId = userDetails.externalId;
    window.location.href =
      "https://yodhadatacollection.app.link/EUohp9obuDb?testid=" + testId;
  };

  return (
    <PageContainer>
      <div className="flex flex-col h-full">
        <Text className="font-bold">Test Complete</Text>
        <div>
          <Text>Thank you for completing the test. Below are your results</Text>
        </div>
        <div className="flex flex-raw space-x-2">
          <div style={{ backgroundColor: "" }} className="w-[40%]">
            Left
          </div>
          <div
            style={{ backgroundColor: "" }}
            className="flex flex-col flex-auto"
          ></div>
          <div style={{ backgroundColor: "" }} className="w-[40%]">
            Right
          </div>
        </div>
        <div className="flex flex-row space-x-2  max-h-[50%]">
          {newTrafficLightResult(category?.leftCategorisation!, "Left")}
          <div
            style={{ backgroundColor: "" }}
            className="flex flex-col flex-auto"
          >
            {intensityKey(category!)}
          </div>
          {newTrafficLightResult(category?.rightCategorisation!, "Right")}
        </div>

        <div className="flex flex-row space-x-2">
          <div
            style={{ backgroundColor: "" }}
            className="w-[40%] flex flex-row"
          >
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              <Text>
                Low <br /> Freq.
              </Text>
            </div>
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              <Text>
                Medium <br /> Freq.
              </Text>
            </div>
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              <Text>
                High <br /> Freq.
              </Text>
            </div>
          </div>
          <div
            style={{ backgroundColor: "" }}
            className="flex flex-col flex-auto"
          ></div>
          <div
            style={{ backgroundColor: "" }}
            className="w-[40%] flex flex-row"
          >
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              <Text>
                Low <br /> Freq.
              </Text>
            </div>
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              <Text>
                Medium <br /> Freq.
              </Text>
            </div>
            <div style={{ backgroundColor: "" }} className="w-[33%]">
              <Text>
                High <br /> Freq.
              </Text>
            </div>
          </div>
        </div>

        <div className="">
          <Text>
            {reportOverallResult(
              category?.leftCategorisation!,
              category?.rightCategorisation!
            )}
          </Text>
        </div>

        <div
          className="W-[100%] h-0 overflow-hidden"
          ref={exportRef}
          id="marker"
        >
          {category && (
            <AudiogramChart
              style={{ width: "600px", height: "500px" }}
              result={category}
            />
          )}
        </div>
        <div className="flex justify-center mt-auto gap-2">
          <Button
            text="Return to Yodha Data Collection App"
            onClick={returnToYodha}
          />
          <Button text="Start New Test" onClick={startNewTest} />
        </div>
      </div>
    </PageContainer>
  );
};
